import type { Timestamp } from '@/interfaces/common';
import { Account } from '../account';
import { Plantation } from '../plantation';
import { Recipient } from '../recipient';
import { User } from '../user';

export interface Order extends Timestamp {
  amount: number;
  number: number;
  course: number;
  status: OrderStatus;
  comment?: string;
  account: Account;
  creator: User;
  recipient: Recipient;
  plantation: Plantation;
}

export enum OrderStatus {
  INITIAL = 'INITIAL',
  IN_PROCESSED = 'IN_PROCESSED',
  SENDED = 'SENDED',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
}

export enum OrderStatusName {
  INITIAL = 'Инициализирован',
  IN_PROCESSED = 'В работе',
  SENDED = 'Отправлено',
  REJECTED = 'Отменён',
  CONFIRMED = 'Оплачен',
}

export const OrderStatusList = [
  { _id: OrderStatus.IN_PROCESSED, name: OrderStatusName.IN_PROCESSED },
  { _id: OrderStatus.SENDED, name: OrderStatusName.SENDED },
  { _id: OrderStatus.REJECTED, name: OrderStatusName.REJECTED },
  { _id: OrderStatus.CONFIRMED, name: OrderStatusName.CONFIRMED },
];

// DTO

export interface CreateOrderDto {
  account: string;
  recipient: string;
  plantation: string;
  amountAccount: string;
  amountRecipient: string;
}

export interface CreateMyselfOrderDto {
  account: string;
  recipientAccount: string;
  amountAccount: string;
  amountRecipient: string;
}
